<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS SOBRE PROVEEDORES:
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de Proveedores</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('009-REC-PRO','c')==1" md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Gestion Proveedor'}">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Proveedor</span>
                            </b-button>
                        </b-col>
                        <b-col  v-if="checkPermissions('009-REC-PRO-INC','r')==1"  md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Incidencias Proveedor'}">
                                <i style="inline-size: auto" class="fas fa-exclamation-circle fa-3x pb-1"></i><br>Incidencias
                            </b-button>
                        </b-col>
                        <b-col  v-if="checkPermissions('009-REC-PRO-CUE','r')==1" md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Evaluaciones Proveedor'}">
                                <i style="inline-size: auto" class="fas fa-star-half-alt fa-3x pb-1"></i><br>Cuestionario de evaluación
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Proveedores registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover show-empty bordered mediun responsive outlined :items="listaProveedores" :fields="campoProveedores" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(criterioEvaluacion)="data">
                                    <span>{{templateCriterio.filter(x =>x.idCriterio == data.value)[0].descripcion}}</span>
                                </template>

                                <template v-slot:cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('009-REC-PRO','u')==1" :to="{name: 'Gestion Proveedor', params: {id: param.item.idProveedor}}" size="sm" v-c-tooltip.hover.click="'Ver detalles'" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" v-c-tooltip.hover.click="'Cuestionarios'" @click="abrirModalCuestionarios(param)" variant="blue" class="mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-cog my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button v-if="checkPermissions('009-REC-PRO','d')==1"  size="sm" class="mr-1 mb-1" @click="eliminarProveedor(param)" v-c-tooltip.hover.click="'Eliminar'" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template #cell(aprobado)="data">
                                    <b-icon variant="success" v-if="data.value=='1'" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template>
                                <template #cell(email)="param">
                                    {{param.item.email | truncate(20)}}
                                </template>
                                <template #cell(razonSocial)="param">
                                    {{param.item.razonSocial | truncate(15)}}
                                </template>
                                <template #cell(nombreDocumentoCalificacion)="param">
                                    {{param.item.nombreDocumentoCalificacion | truncate(15)}}
                                </template>
                                <template #cell(contacto)="param">
                                    {{param.item.contacto | truncate(10)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalCuestionarios" :closeOnBackdrop="false" color="primario" size="lg">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Cuestionarios</span></h6>
            <CButtonClose @click="modalCuestionarios = false" class="text-white" />
        </template>
        <b-row>
            <b-col md="12">
                <b-table bordered :items="listaCuestionariosProveedor" :fields="camposCuestionarios" class="table-custom mt-2" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                    <template v-slot:cell(fechaCompletado)="data">
                        <router-link v-if="data.item.fechaCompletado != null" style="text-decoration:none;" :to="{name:'Gestion Evaluacion Proveedores', params: {id: data.item.idEvaluacionProveedor}}">
                            <li class="fas fa-share fa-sm mr-1"></li> {{data.value}}
                        </router-link>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="12" class="text-right my-2">
                <b-button size="md" variant="danger" @click="modalCuestionarios = false">
                    Cerrar
                </b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    components: {
        CChartPie
    },
    data() {
        return {
            labelEstadistica: ["Aprobados", "No aprobados"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            datosSession: {
                idCliente: '',
            },
            listaCuestionarios: [],
            listaCuestionariosProveedor: [],

            listaProveedores: [],
            campoProveedores: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Código Proveedor",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Denominación",
                    class: "text-center",
                },
                {
                    key: "ruc",
                    label: "Ruc",
                    class: "text-center",
                },
                {
                    key: "contacto",
                    label: "Contacto",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "email",
                    label: "Email",
                    class: "text-center",
                },
                {
                    key: "criterioEvaluacion",
                    label: "Criterio de Evaluación",
                    class: "text-center",
                },
                {
                    key: "nombreDocumentoCalificacion",
                    label: "Doc. de Calificación",
                    class: "text-center",
                },
                {
                    key: "aprobado",
                    label: "¿Aprobado?",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            camposCuestionarios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "fechaEnviado",
                    label: "Envíos de cuestionario",
                    class: "text-center",
                }, {
                    key: "fechaCompletado",
                    label: "Cuestionarios completados",
                    class: "text-center",
                },
            ],

            templateCriterio: [{
                idCriterio: 1,
                descripcion: "Excelente"
            }, {
                idCriterio: 2,
                descripcion: "Satisfactorio"
            }, {
                idCriterio: 3,
                descripcion: "Intermedio"
            }, {
                idCriterio: 4,
                descripcion: "Básico"
            }, {
                idCriterio: 5,
                descripcion: "No Apto"
            }, ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],
            modalCuestionarios: false,

            rutaRazonSocial: '',
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarProveedores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proveedores", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaProveedores = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalCuestionarios(param) {
            this.listaCuestionariosProveedor = this.listaCuestionarios.filter(x => x.idProveedor == param.item.idProveedor);
            this.rutaRazonSocial = param.item.razonSocial;
            this.modalCuestionarios = true;
        },

        listarCuestionariosCompletados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-proveedor-completados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaCuestionarios = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        eliminarProveedor(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el proveedor?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-proveedor", {
                                idProveedor: param.item.idProveedor
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarProveedores();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },

    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#428bca94',
                    '#b02b2c9e',
                ],
                data: [this.listaProveedores.filter(x => x.aprobado == 1).length, this.listaProveedores.filter(x => x.aprobado == 2).length]
            }]
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarCuestionariosCompletados();
            this.listarProveedores();
        }
    }

}
</script>
